<template>
  <div class="w1092">
    <div class="tab">
      <a @click="currentTab = 'usdt'" :class="{'on': currentTab == 'usdt'}">USDT (TRC20) 출금신청</a>
      <a @click="currentTab = 'ptp'" :class="{'on': currentTab == 'ptp'}">P2P로 판매하기</a>
    </div>
    <template v-if="currentTab == 'usdt'">
      <div class="moneyread">
        <ul>
          <h3>내 지갑으로 출금신청</h3>
          <li>USDT (TRC20) 코인으로 24시간 언제든지 출금이 가능합니다.</li>
          <li>USDT (TRC20) 코인이 아니거나 출금주소를 잘못 입력 할 경우 암호화 화폐의 특성상 취소가 불가능합니다.</li>
        </ul>
      </div>
      <div class="moneyinfoappli mt30">
        <div class="appliinputwrap">
          <div class="inputLeft w430">
            <p class="input">
                <i>코인종류</i>
                <select>
                  <option>USDT</option>
                </select>
            </p>
            <p class="input">
                <i>네트워크</i>
                <select>
                  <option>TRC20</option>
                </select>
            </p>
          </div>
          <div class="inputLeft">
            <p class="input">
                <input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.noValidPrice')" v-model="amount"/>
                <em>USDT</em>
            </p>
            <div class="calc">
                <a @click="calculation">계산하기</a>
                <p class="input">
                    <i><img src="@/assets/img/icon_coin.svg" />출금캐시</i>
                    <span>{{thousand(price)}}</span>
                    <em></em>
                </p>
            </div>
          </div>
        </div>
        <div class="fx-40">
          <ul class="">
            <li>출금비밀번호</li>
            <li><input type="text" class="numb" pattern="[0-9.,]+" v-model="cashOutPass"/></li>
          </ul>
          <p class="wallet">
            <input type="text" placeholder="출금 지갑 주소" class="numb" v-model="to_address">
            <img src="@/assets/img/icon_copy.svg" />
          </p>
        </div>
      </div>
      <div class="moneyinfoappli mt30">
        <div class="appliinputwrap">
          <div>
            <ul class="appliinput">
              <li>보유금액</li>
              <li>{{thousand(userData.cashAmt)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="appliinput">
              <li>{{$t('front.board.withdrawMoney')}}</li>
              <li>{{thousand(price)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="appliinput">
              <li>출금 후 예상금액</li>
              <li>{{thousand(userData.cashAmt - price)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="moneybtnwrap">
              <li class="one"><a @click="setMoney(5)">5</a></li>
              <li class="one"><a @click="setMoney(10)">10</a></li>
              <li class="one"><a @click="setMoney(20)">20</a></li>
              <li class="two"><a @click="setMoney(50)">50</a></li>
              <li class="two"><a @click="setMoney(80)">80</a></li>
              <li class="two"><a @click="setMoney(100)">100</a></li>
              <li class="thr"><a @click="setMoney(200)">200</a></li>
              <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
            </ul>
          </div>
          <div>
            <ul class="appliresult">
              <li>
                  <span>출금 신청한 USDT</span>
                  <span>{{thousand(amount)}}<em></em> usdt</span>
              </li>
              <li>
                  <span>현재 환율</span>
                  <span><em></em>{{thousand(usdtToKRW)}}</span>
              </li>
              <li>
                  <span>출금 수수료</span>
                  <span>0</span>
              </li>
              <li>
                  <span>최종 출금 금액</span>
                  <span>{{thousand(price)}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="applibtns">
          <a @click="onSubmit">{{thousand(price)}} 캐시 {{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>

    </template>

    <template v-if="currentTab == 'ptp'">
      <div class="moneyread">
        <ul>
          <h3>P2P로 판매하기</h3>
          <li>USDT (TRC20) 코인으로 24시간 언제든지 출금이 가능합니다.</li>
          <li>USDT (TRC20) 코인이 아니거나 출금주소를 잘못 입력 할 경우 암호화 화폐의 특성상 취소가 불가능합니다.</li>
        </ul>
      </div>
      <div class="moneyinfoappli mt30">
        <div class="appliinputwrap">
          <div class="inputLeft w430">
            <p class="input">
                <i>코인종류</i>
                <select>
                  <option>USDT</option>
                </select>
            </p>
            <p class="input">
                <i>네트워크</i>
                <select>
                  <option>TRC20</option>
                </select>
            </p>
          </div>
          <div class="inputLeft">
            <p class="input">
              <input type="text" class="numb w100w" pattern="[0-9.,]+" :placeholder="$t('front.cash.noValidPrice')" v-model="amount"/>
                <em>USDT</em>
            </p>
            <div class="calc">
              <a @click="calculation">계산하기</a>
              <p class="input">
                <i><img src="@/assets/img/icon_coin.svg" />출금캐시</i>
                <span>{{thousand(price)}}</span>
                <em></em>
              </p>
            </div>
          </div>
        </div>
        <div class="fx-40">
          <ul class="">
            <li>예금주</li>
            <li><input type="text" class="numb" v-model="bank_holder"/></li>
          </ul>
          <ul class="">
            <li>은행명</li>
            <li><input type="text" class="numb" v-model="bank_name"/></li>
          </ul>
          <ul class="">
            <li>계좌번호</li>
            <li><input type="text" class="numb" pattern="[0-9.,]+" v-model="bank_account"/></li>
          </ul>
          <ul class="">
            <li>출금비밀번호</li>
            <li><input type="text" class="numb" pattern="[0-9.,]+" v-model="cashOutPass"/></li>
          </ul>
        </div>
      </div>

      <div class="moneyinfoappli mt30">
        <div class="appliinputwrap">
          <div>
            <ul class="appliinput">
              <li>보유금액</li>
              <li>{{thousand(userData.cashAmt)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="appliinput">
              <li>{{$t('front.board.withdrawMoney')}}</li>
              <li>{{thousand(price)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="appliinput">
              <li>출금 후 예상금액</li>
              <li>{{thousand(userData.cashAmt - price)}}{{$t('front.common.money')}}</li>
            </ul>
            <ul class="moneybtnwrap">
              <li class="one"><a @click="setMoney(5)">5</a></li>
              <li class="one"><a @click="setMoney(10)">10</a></li>
              <li class="one"><a @click="setMoney(20)">20</a></li>
              <li class="two"><a @click="setMoney(50)">50</a></li>
              <li class="two"><a @click="setMoney(80)">80</a></li>
              <li class="two"><a @click="setMoney(100)">100</a></li>
              <li class="thr"><a @click="setMoney(200)">200</a></li>
              <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
            </ul>
          </div>
          <div>
            <ul class="appliresult">
              <li>
                <span>출금 신청한 USDT</span>
                <span>{{thousand(amount)}}<em></em> usdt</span>
              </li>
              <li>
                <span>현재 환율</span>
                <span><em></em>{{thousand(usdtToKRW)}}</span>
              </li>
              <li>
                <span>출금 수수료</span>
                <span>0</span>
              </li>
              <li>
                <span>최종 출금 금액</span>
                <span>{{thousand(price)}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="applibtns">
          <a @click="onSubmitP2P">{{thousand(price)}} 캐시 {{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>

    </template>
    <div class="board">
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.withdrawMoney')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getUSDTWithdraw, getUSDTWithdrawP2P } from '@/api/usdt'
import { mapState } from 'vuex'
import { thousand } from '@/libs/utils'

export default {
  name: 'Exchange',
  components: {
    Pagination
  },
  watch: {
    currentTab () {
      this.price = ''
      this.amount = ''
    },
    amount () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.amount.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.amount = n
    }
  },
  async created () {
    this.usdtToKRW = await this.getUSDT()
    this.onCashListSearch()
    console.log(this.usdtToKRW)
  },
  data () {
    return {
      cashType: 'out',
      cashList: [],
      currentTab: 'usdt',
      usdtToKRW: 0,
      amount: '',
      price: 0,
      to_address: '',
      cashOutPass: '',
      bank_name: '',
      bank_account: '',
      bank_holder: ''
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    thousand,
    calculation () {
      if (this.amount && this.amount !== '') {
        const amount = Number(this.amount.replace(/,/g, ''))
        if (amount * this.usdtToKRW > this.userData.cashAmt) {
          this.amount = ''
          this.onCheck('현재 보유캐시보다 큽니다.')
        } else {
          this.price = amount * this.usdtToKRW
        }
      }
    },
    setMoney (value) {
      if (value) {
        let amount = Number(this.amount.replace(/,/g, '')) + value
        if (amount * this.usdtToKRW > this.userData.cashAmt) {
          amount = ''
          this.onCheck('현재 보유캐시보다 큽니다.')
        } else {
          this.price = amount * this.usdtToKRW
        }

        this.amount = thousand(amount)
      } else {
        this.amount = ''
        this.price = 0
      }
    },
    onSubmit () {
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (!this.amount) {
        this.onCheck('코인 수량을 입력해주세요.')
        return false
      }
      if (!this.price) {
        this.onCheck('계산하기를 눌러주세요.')
        return false
      }
      if (!this.to_address) {
        this.onCheck('지갑 주소를 입력해주세요.')
        return false
      }

      if (confirm('출금 신청하시겠습니까?')) {
        const params = {
          cashOutPass: this.cashOutPass,
          to_address: this.to_address,
          amount: Number(this.amount.replace(/,/g, '')),
          price: this.price
        }
        getUSDTWithdraw(params).then(async res => {
          const result = res.data
          if (result.resultCode === '0') {
            await this.onCheck('정상적으로 출금 신청되었습니다.')
            location.reload()
          } else {
            this.onCheck(result.resultMessage)
          }
        })
      }
    },
    onSubmitP2P () {
      if (!this.cashOutPass) {
        this.onCheck('출금 비밀번호를 입력해주세요.')
        return false
      }
      if (!this.amount) {
        this.onCheck('코인 수량을 입력해주세요.')
        return false
      }
      if (!this.price) {
        this.onCheck('계산하기를 눌러주세요.')
        return false
      }

      if (!this.bank_holder) {
        this.onCheck('예금주를 입력해주세요.')
        return false
      }
      if (!this.bank_name) {
        this.onCheck('은행명을 입력해주세요.')
        return false
      }
      if (!this.bank_account) {
        this.onCheck('계좌번호를 입력해주세요.')
        return false
      }

      if (confirm('출금 신청하시겠습니까?')) {
        const params = {
          cashOutPass: this.cashOutPass,
          to_address: this.to_address,
          amount: Number(this.amount.replace(/,/g, '')),
          price: this.price
        }

        getUSDTWithdrawP2P(params).then(async res => {
          const result = res.data
          if (result.resultCode === '0') {
            await this.onCheck('정상적으로 출금 신청되었습니다.')
            location.reload()
          } else {
            this.onCheck(result.resultMessage)
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.tab {display: flex;align-items: center;gap: 15px;margin-bottom: 20px;}
.tab a {width: 180px;
    height: 45px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    border: 1px solid transparent;
    background-image: linear-gradient(to bottom, #6a6b6f, #404450), linear-gradient(to bottom, #b0772a, #ddb763 16%, #f4d780 25%, #f4d780 46%, #fbf9d8 65%, #f4d780 75%, #b0772a);
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    align-items: center;
    justify-content: center;}
.tab a:hover, .tab a.on {background: #ceb790;}
.inputLeft {width: 777px;}
.inputLeft .input, .input {height: 54px;border: solid 1px #53474b;font-size: 20px;display: flex;align-items: center;justify-content: end;gap: 10px;padding: 0 25px;margin-bottom: 35px;}
.inputLeft .input {padding: 0 10px;}
.input i {margin-right: auto;font-size: 16px;display: flex;gap: 18px;align-items: center;}
.input i img {vertical-align: middle;}
.input .pw {font-size: 16px;text-align: center;margin: 0 auto;}
.inputLeft input {border: 0;background: none;color: #ff9ea8;font-size: 20px;text-align: right;}
.inputLeft .check {font-size: 16px;display: flex;align-items: center;justify-content: center;gap: 12px;margin: 11px 0 25px;}
.applibtns a {border-radius: 5px;background-color: #9d303a;border: 1px solid #6d6d6d;width: 200px !important;height: 88px;font-size: 22px;display: flex;align-items: center;justify-content: center;}
.fx-40 {display: flex;gap: 40px;}
.fx-40 ul {display: flex;flex-direction: column;gap: 5px;}
.fx-40 ul, .fx-40 ul li {width: 100%;}
.fx-40 ul li input {width: 100%;height: 54px;
    border: solid 1px #53474b;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    padding: 0 25px;
    margin-bottom: 35px;}
.calc {display: flex;gap: 22px;}
.calc a {width: 176px;height: 54px;display: flex;align-items: center;justify-content: center;font-size: 16px;border-radius: 5px;border: solid 1px #6d6d6d;background-color: #9d303a;color: #fff;}
.calc .input {width: 506px;}
.wallet {width: 100%;height: 54px;border: solid 1px #53474b;background-color: #fff;padding: 0 25px 0 18px;display: flex;align-items: center;gap: 20px;margin-top: 20px;}
.wallet input {width: 100%;background: none;border: 0;padding: 0;color: #000;font-size: 16px;}
.coin {width: 719px;}
.coin .input span {display: flex;align-items: center;gap: 10px;}
.coin .input em {width: 26px;height: 26px;background: #2a9e7c;border-radius: 30px;display: block;}
.inputRight.coinInfo {width: 458px;background: #1e1b1c;padding: 30px 25px;font-size: 16px;}
.coinInfo ul {overflow: hidden;}
.coinInfo em {color: #716c6e;margin-bottom: 10px;display: block;}
.coinInfo span {color: #b2a394;display: block;}
.coinInfo li:nth-child(1), .coinInfo li:nth-child(2) {margin-bottom: 55px;}
.coinInfo li {width: 50%;float: left;}
.info {display: flex;align-items: center;gap: 22px;margin-bottom: 40px;}
.info li {border: solid 1px #53474b;background-color: #1e1b1c;width: 100%;margin: 0 !important;padding: 14px 18px;}
.cash {color: #ff9ea8;font-size: 22px;}
.input select {font-size: 16px;color: #6d6d6d;background: none;border: 0;}
.chargeList {padding: 28px 30px 30px 30px;border: solid 1px #53474b;}
.inputRight {border: solid 1px #53474b;width: 400px;}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
